/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";

import { useTrack } from "@rewards-web/shared/modules/analytics";
import { AppTheme } from "@rewards-web/shared/style/types";

import { AppStore } from "../../../graphics/app-store";
import googlePlayImageUrl from "../../../graphics/play-store.png";
import { SchedulingSoftwareDetails } from "../../lib";

export function SchedulingSoftwareAppLinks(props: {
  details: SchedulingSoftwareDetails;
}) {
  const track = useTrack();

  return (
    <div
      css={(appTheme: AppTheme) => css`
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
        gap: ${appTheme.spacing(2)};
        row-gap: ${appTheme.spacing(1)};
        margin-bottom: ${appTheme.spacing(1)};
      `}
    >
      <a
        href={props.details.appStoreLink}
        target="_blank"
        rel="noopener noreferrer"
        onClick={() =>
          track("Clicked evv streak goal modal app link", {
            schedulingSoftware: props.details.appName,
            type: "ios_app_store",
          })
        }
      >
        <AppStore height={50} />
      </a>

      <a
        href={props.details.playStoreLink}
        target="_blank"
        rel="noopener noreferrer"
        onClick={() =>
          track("Clicked evv streak goal modal app link", {
            schedulingSoftware: props.details.appName,
            type: "google_play",
          })
        }
      >
        <img
          css={css`
            height: 51px;
          `}
          src={googlePlayImageUrl}
          alt="Google Play"
        />
      </a>
    </div>
  );
}
