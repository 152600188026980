/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { useState } from "react";

import { Alert } from "@rewards-web/shared/components/alert";
import { Button } from "@rewards-web/shared/components/button";
import { Divider } from "@rewards-web/shared/components/divider";
import { PageLoadingState } from "@rewards-web/shared/components/page-loading-state";
import { Typography } from "@rewards-web/shared/components/typography";
import {
  RedemptionMethod,
  RewardsOrganizationCountry,
} from "@rewards-web/shared/graphql-types";
import { useTrack } from "@rewards-web/shared/modules/analytics";
import { reportError } from "@rewards-web/shared/modules/error";
import { useFormatters } from "@rewards-web/shared/modules/formatter";
import { AppTheme } from "@rewards-web/shared/style/types";

import { EarnMorePointsButton } from "../../../earn-more-points-button";
import { InsufficientPointsDrawer } from "../../../points-progress/insufficient-points-drawer";
import { RedeemPointsDrawer } from "../../../points-progress/redeem-points-drawer";
import { DisclaimersFooter } from "./disclaimers-footer";
import { Giftcard } from "./gift-card";
import { useListGiftCardOptionsQuery } from "./list-gift-card-options.generated";
import SeeMoreCard from "./see-more-card.png";

interface GiftCardListProps {
  isLocked: boolean;
  pointsAvailableToRedeem: number;
  pointsPerDollar: number;
  minimumPointsToRedeem: number;
  email: string | null | undefined;
  phoneNumber: string | null | undefined;
  country: RewardsOrganizationCountry;
}

export function GiftCardList({
  isLocked,
  phoneNumber,
  pointsAvailableToRedeem,
  pointsPerDollar,
  minimumPointsToRedeem,
  email,
  country,
}: GiftCardListProps) {
  const { formatMessage } = useFormatters();
  const track = useTrack();

  const [
    isInsufficientPointsDrawerOpen,
    setIsInsufficientPointsDrawerOpen,
  ] = useState(false);
  const [isRedeemDrawerOpen, setIsRedeemDrawerOpen] = useState(false);

  const { data, error } = useListGiftCardOptionsQuery({
    fetchPolicy: "cache-first",
    onError: reportError,
  });

  if (error) {
    return (
      <Alert
        severity="error"
        message={formatMessage({
          description: "Points summary page > redeem page > list gift cards",
          defaultMessage: "Something went wrong. Please try again later.",
        })}
      />
    );
  }

  if (!data) {
    return <PageLoadingState />;
  }

  return (
    <>
      <Typography variant="h3">
        {isLocked
          ? formatMessage({
              defaultMessage: "🔒 Discover rewards",
              description: "Points summary page > redeem > locked header",
            })
          : formatMessage({
              defaultMessage: "🤩 Redeem rewards now!",
              description: "Points summary page > redeem > unlocked header",
            })}
      </Typography>
      <Typography
        variant="body"
        css={(appTheme: AppTheme) => css`
          margin-bottom: ${appTheme.spacing(2)};
          color: ${appTheme.palette.grey[800]};
        `}
      >
        {(() => {
          if (isLocked) {
            return formatMessage(
              {
                defaultMessage:
                  "Earn {required_points, number} more points to unlock rewards from your favourite stores!",
                description: "Points summary page > redeem > locked subtitle",
              },
              {
                required_points:
                  minimumPointsToRedeem - pointsAvailableToRedeem,
              }
            );
          }

          if (country === RewardsOrganizationCountry.Us) {
            return formatMessage({
              defaultMessage:
                "Select a card below to change your points into cash within a few clicks. You will need an email address.",
              description: "Points summary page > redeem > unlocked subtitle",
            });
          } else {
            return formatMessage({
              defaultMessage:
                "Redeem your gift cards now! Click the button below to receive a link to your gift card options.",
              description:
                "Points summary page > redeem > unlocked subtitle canada",
            });
          }
        })()}
      </Typography>

      {isLocked && <EarnMorePointsButton />}

      {country === RewardsOrganizationCountry.Us && (
        <div
          css={(theme: AppTheme) => css`
            display: grid;
            grid-template-columns: repeat(1, 1fr);
            @media (min-width: 320px) {
              grid-template-columns: repeat(2, 1fr);
            }
            @media (min-width: 550px) {
              grid-template-columns: repeat(3, 1fr);
            }

            gap: ${theme.spacing(2)};
            margin-bottom: ${theme.spacing(3)};
          `}
        >
          {data?.listGiftCardOptions?.items.map((reward, index) => (
            <Giftcard
              key={index}
              imageUrl={reward.imageUrl}
              brandName={reward.brandName}
              minPointsAllowed={reward.minPointsAllowed}
              maxPointsAllowed={reward.maxPointsAllowed}
              isLocked={isLocked}
              setIsInsufficientPointsDrawerOpen={
                setIsInsufficientPointsDrawerOpen
              }
              setIsRedeemDrawerOpen={setIsRedeemDrawerOpen}
            />
          ))}
          {/* see more card */}
          <Giftcard
            imageUrl={SeeMoreCard}
            brandName={formatMessage({
              defaultMessage: "More Gift Cards",
              description: "Points summary page > redeem > more gift Cards",
            })}
            minPointsAllowed={1000}
            maxPointsAllowed={20000}
            isLocked={isLocked}
            setIsInsufficientPointsDrawerOpen={
              setIsInsufficientPointsDrawerOpen
            }
            setIsRedeemDrawerOpen={setIsRedeemDrawerOpen}
          />
        </div>
      )}

      {country === RewardsOrganizationCountry.Ca && !isLocked && (
        <Button
          color="secondary"
          label={formatMessage({
            defaultMessage: "Redeem rewards now",
            description:
              "Points summary page > redeem > redeem rewards now button label",
          })}
          width="full"
          css={(appTheme: AppTheme) => css`
            display: flex;
            padding: ${appTheme.spacing(1)} 0;
            margin-bottom: ${appTheme.spacing(3)};
            justify-content: center;
            align-items: center;
            gap: 10px;
            align-self: stretch;
          `}
          onClick={() => {
            if (isLocked) {
              setIsInsufficientPointsDrawerOpen(true);
            } else {
              setIsRedeemDrawerOpen(true);
            }

            track("Redeem rewards now button clicked", {
              country: country,
              email: email,
            });
          }}
        />
      )}

      <RedeemPointsDrawer
        phoneNumber={phoneNumber}
        open={isRedeemDrawerOpen}
        onClose={() => setIsRedeemDrawerOpen(false)}
        pointsAvailableToRedeem={pointsAvailableToRedeem}
        pointsPerDollar={pointsPerDollar}
        details={{
          redemptionMethod: RedemptionMethod.GenericGiftCardLink,
        }}
        email={email}
      />
      <InsufficientPointsDrawer
        open={isInsufficientPointsDrawerOpen}
        onClose={() => setIsInsufficientPointsDrawerOpen(false)}
        pointsAvailableToRedeem={pointsAvailableToRedeem}
        minimumPointsToRedeem={minimumPointsToRedeem}
      />

      {country === RewardsOrganizationCountry.Us && (
        <>
          <Divider
            css={(theme: AppTheme) => css`
              margin-bottom: ${theme.spacing(2)};
            `}
          />
          <DisclaimersFooter />
        </>
      )}
    </>
  );
}
