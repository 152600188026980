/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import CloseOutlinedIcon from "@material-ui/icons/CloseOutlined";
import { useMemo, useState } from "react";

import { ResponsiveDialog } from "@rewards-web/shared/components/responsive-dialog";
import { Typography } from "@rewards-web/shared/components/typography";
import { SchedulingSoftware } from "@rewards-web/shared/graphql-types";
import { useTrack } from "@rewards-web/shared/modules/analytics";
import { useFormatters } from "@rewards-web/shared/modules/formatter";
import { AppTheme } from "@rewards-web/shared/style/types";

import { GoalCardProps } from "..";
import { AppStore } from "../../graphics/app-store";
import hhaPhoneImageUrl from "../../graphics/hha-phone-large.png";
import googlePlayImageUrl from "../../graphics/play-store.png";
import { BaseGoalCard } from "../base-goal-card";
import { useGoalRewardsUserDataQuery } from "../goal-rewards-user.query.generated";
import {
  getSchedulingSoftwareDetails,
  useCommonBaseGoalCardProps,
} from "../lib";

const HHAX_APP_STORE_URL =
  "https://apps.apple.com/us/app/hhaexchange/id883673336";
const HHAX_GOOGLE_PLAY_URL =
  "https://play.google.com/store/apps/details?id=com.hhaexchange.caregiver";
const HELP_ARTICLE_URL =
  "https://support.caribou.care/hc/en-us/articles/26239332119319-Install-the-HHAeXchange-Mobile-App";

export function HHAXLoginGoalCard({ goal, cardContext }: GoalCardProps) {
  const { formatMessage } = useFormatters();
  const [open, setOpen] = useState(false);
  const track = useTrack();

  const { data } = useGoalRewardsUserDataQuery();

  const schedulingSoftwareDetails = useMemo(
    () =>
      getSchedulingSoftwareDetails({
        schedulingSoftware: SchedulingSoftware.Hhaexchange,
        stateAbbreviation:
          data?.getMyRewardsOrganization.mailingAddress?.__typename ===
          "USMailingAddress"
            ? data.getMyRewardsOrganization.mailingAddress.stateAbbreviation
            : undefined,
      }),
    [data]
  );

  const commonBaseGoalCardProps = useCommonBaseGoalCardProps({
    goal,
    cardContext,
    pathToTrack: "/goal-track/evv",
  });

  const buttonLabel = formatMessage({
    defaultMessage: "Download App",
    description: "Goal card > hhax login > button label",
  });

  return (
    <>
      <ResponsiveDialog
        open={open}
        onClose={() => setOpen(false)}
        maxWidth="500px"
      >
        <div
          css={(theme: AppTheme) => css`
            padding: ${theme.spacing(4)};
          `}
        >
          <div
            css={css`
              display: flex;
              justify-content: flex-end;
              width: 100%;
            `}
          >
            <button
              css={css`
                border-radius: 50%;
                border-width: 0px;
                height: 27px;
                width: 27px;
                background-color: #e0e0e0;
                padding: 0;
                cursor: pointer;
              `}
              onClick={() => setOpen(false)}
            >
              <CloseOutlinedIcon
                css={css`
                  padding-top: 3px;
                  font-size: 1.6em;
                  color: #757575;
                `}
              />
            </button>
          </div>

          <Typography
            css={(theme: AppTheme) => css`
              padding-bottom: ${theme.spacing(4)};
              text-align: center;
            `}
            variant="h5"
            color="text.primary"
          >
            {commonBaseGoalCardProps.title}
          </Typography>

          <Typography
            css={(theme: AppTheme) => css`
              padding-bottom: ${theme.spacing(4)};
              text-align: center;
            `}
            variant="body"
            color="text.primary"
          >
            {formatMessage({
              defaultMessage:
                "Install the mobile app to easily clock in and out, view your schedules, and communicate with your agency.",
              description: "Goal card > hhax login modal > description",
            })}
          </Typography>

          <Typography
            css={(theme: AppTheme) => css`
              padding-bottom: ${theme.spacing(4)};
              text-align: center;
            `}
            variant="body"
            color="text.primary"
          >
            <a
              href={HELP_ARTICLE_URL}
              target="_blank"
              rel="noopener noreferrer"
            >
              {formatMessage({
                defaultMessage: "Show me how",
                description: "Goal card > hhax login modal > instructions link",
              })}
            </a>
          </Typography>

          <div
            css={(appTheme: AppTheme) => css`
              display: flex;
              justify-content: space-around;
              align-items: stretch;
              gap: 1rem;
              flex-wrap: wrap;
              margin-bottom: ${appTheme.spacing(2)};
            `}
          >
            <a
              href={
                schedulingSoftwareDetails?.appStoreLink ?? HHAX_APP_STORE_URL
              }
              target="_blank"
              rel="noopener noreferrer"
              onClick={() =>
                track("Clicked hhax login goal modal app link", {
                  type: "ios_app_store",
                })
              }
            >
              <AppStore height={50} />
            </a>

            <a
              href={
                schedulingSoftwareDetails?.playStoreLink ?? HHAX_GOOGLE_PLAY_URL
              }
              target="_blank"
              rel="noopener noreferrer"
              onClick={() =>
                track("Clicked hhax login goal modal app link", {
                  type: "google_play",
                })
              }
            >
              <img
                css={css`
                  height: 51px;
                `}
                src={googlePlayImageUrl}
                alt="Google Play"
              />
            </a>
          </div>
        </div>
      </ResponsiveDialog>

      <BaseGoalCard
        {...commonBaseGoalCardProps}
        icon={<img src={hhaPhoneImageUrl} alt="phone" />}
        detailText={formatMessage({
          defaultMessage:
            "Download the HHAeXchange app to earn points for easily clocking in and out of shifts.",
          description: "Goal card > hhax login > details text",
        })}
        primaryButtonProps={{
          label: buttonLabel,
          onClick: () => {
            setOpen(true);
          },
        }}
      />
    </>
  );
}
