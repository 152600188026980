/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import {
  faClock,
  faCheckCircle,
  faDollarCircle,
  faMobile,
} from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { Typography } from "@rewards-web/shared/components/typography";
import { BillableHoursGoalTracker } from "@rewards-web/shared/graphql-types";
import { reportError } from "@rewards-web/shared/modules/error";
import { useFormatters } from "@rewards-web/shared/modules/formatter";
import { AppTheme } from "@rewards-web/shared/style/types";

import briefcaseLargeUrl from "../../../../shared/goal/graphics/briefcase-large.png";
import { BaseGoalTrackPage, BaseGoalTrackPageProps } from "../base-track-page";
import { EVVTrackPageGoalDescription } from "../evv-goal-track-page/evv-track-page-goal-description";
import { useBillableHoursTrackPageDataQuery } from "./billable-hours-track-page-data.generated";

export interface BillableHoursTrackPageProps {
  trackData: BaseGoalTrackPageProps["trackData"];
  analyticsData: BaseGoalTrackPageProps["analyticsData"];
}

export function BillableHoursTrackPage({
  trackData,
  analyticsData,
}: BillableHoursTrackPageProps) {
  const { formatMessage } = useFormatters();

  const requiresEVVCompliance =
    trackData.loading === false &&
    trackData.availableGoals.length > 0 &&
    (trackData.availableGoals[0].goalTracker as BillableHoursGoalTracker)
      .requiresEVVCompliance;

  const { data, loading: dataLoading } = useBillableHoursTrackPageDataQuery({
    skip: !requiresEVVCompliance,
    onError: reportError,
  });

  return (
    <BaseGoalTrackPage
      trackData={trackData}
      analyticsData={analyticsData}
      title={formatMessage({
        defaultMessage: "Billable Hours",
        description: "Billable Hours Track Page > title",
      })}
      icon={<img src={briefcaseLargeUrl} alt="briefcase" />}
      content={
        <>
          <Typography
            variant="body"
            color="text.primary"
            fontWeight="bold"
            marginBottom={2}
          >
            {formatMessage({
              defaultMessage: "How it works",
              description: "Billable Hours Track Page > how it works",
            })}
          </Typography>

          <div
            css={(theme: AppTheme) =>
              css`
                display: flex;
                gap: ${theme.spacing(2)};
                margin-bottom: ${theme.spacing(1.5)};
              `
            }
          >
            <FontAwesomeIcon
              icon={faClock}
              color="grey"
              fontSize={18}
              css={(theme: AppTheme) => css`
                padding-top: ${theme.spacing(0.5)};
              `}
            />
            <Typography variant="body" color="text.primary">
              {formatMessage({
                defaultMessage: "Check your schedule first to avoid conflicts.",
                description: "Billable Hours Track Page > content > item 1",
              })}
            </Typography>
          </div>

          <div
            css={(theme: AppTheme) =>
              css`
                display: flex;
                gap: ${theme.spacing(2)};
                margin-bottom: ${theme.spacing(1.5)};
              `
            }
          >
            <FontAwesomeIcon
              icon={faCheckCircle}
              color="grey"
              fontSize={18}
              css={(theme: AppTheme) => css`
                padding-top: ${theme.spacing(0.5)};
              `}
            />
            <Typography variant="body" color="text.primary">
              {formatMessage({
                defaultMessage:
                  'Click "I\'m Interested" to let your coordinator know you want to increase hours.',
                description: "Billable Hours Track Page > content > item 2",
              })}
            </Typography>
          </div>

          <div
            css={(theme: AppTheme) =>
              css`
                display: flex;
                gap: ${theme.spacing(2)};
                margin-bottom: ${theme.spacing(1.5)};
              `
            }
          >
            <FontAwesomeIcon
              icon={faDollarCircle}
              color="grey"
              fontSize={18}
              css={(theme: AppTheme) => css`
                padding-top: ${theme.spacing(0.5)};
              `}
            />
            <Typography variant="body" color="text.primary">
              {formatMessage({
                defaultMessage:
                  "You’ll earn points when you complete the goal. Shift data may take several days to be reflected.",
                description: "Billable Hours Track Page > content > item 3",
              })}
            </Typography>
          </div>

          {requiresEVVCompliance && (
            <div
              css={(theme: AppTheme) =>
                css`
                  display: flex;
                  gap: ${theme.spacing(2)};
                  margin-bottom: ${theme.spacing(1.5)};
                `
              }
            >
              <FontAwesomeIcon
                icon={faMobile}
                color="grey"
                fontSize={18}
                css={(theme: AppTheme) => css`
                  padding-top: ${theme.spacing(0.5)};
                `}
              />
              <EVVTrackPageGoalDescription
                evvRulesLoading={dataLoading}
                evvRules={data?.getMyRewardsOrganizationEVVRules.rules}
                css={css`
                  margin-top: 0;
                `}
              />
            </div>
          )}
        </>
      }
    />
  );
}
