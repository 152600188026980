import * as Types from '@rewards-web/shared/graphql-types';

import { gql } from '@apollo/client';
export type GoalCardFragmentFragment = (
  { __typename?: 'Goal' }
  & Pick<Types.Goal, 'id' | 'type' | 'status' | 'numPoints' | 'numCaribouSponsoredDrawTickets' | 'numOrganizationDrawTickets' | 'progress' | 'trackName' | 'expiresAt' | 'activatedAt' | 'achievedAt'>
  & { goalTracker: (
    { __typename: 'JobShareGoalTracker' }
    & Pick<Types.JobShareGoalTracker, 'id'>
  ) | (
    { __typename: 'SurveyGoalTracker' }
    & Pick<Types.SurveyGoalTracker, 'id' | 'surveyType'>
  ) | (
    { __typename: 'EVVStreakGoalTracker' }
    & Pick<Types.EvvStreakGoalTracker, 'id' | 'targetStreak' | 'currentStreak'>
    & { trackedVisits: Array<(
      { __typename?: 'EVVStreakGoalTrackerVisitDetail' }
      & Pick<Types.EvvStreakGoalTrackerVisitDetail, 'id' | 'rangeStart' | 'rangeEnd' | 'status'>
    )> }
  ) | (
    { __typename: 'WatchVideoGoalTracker' }
    & Pick<Types.WatchVideoGoalTracker, 'id' | 'videoType'>
  ) | (
    { __typename: 'HHAXLoginGoalTracker' }
    & Pick<Types.HhaxLoginGoalTracker, 'id'>
  ) | (
    { __typename: 'LastMinuteShiftGoalTracker' }
    & Pick<Types.LastMinuteShiftGoalTracker, 'id' | 'availability'>
    & { trackedVisit?: Types.Maybe<(
      { __typename?: 'LastMinuteShiftGoalTrackerVisitDetail' }
      & Pick<Types.LastMinuteShiftGoalTrackerVisitDetail, 'id' | 'rangeStart'>
    )> }
  ) | (
    { __typename: 'CompleteTrainingGoalTracker' }
    & Pick<Types.CompleteTrainingGoalTracker, 'id' | 'programDurationCompletedSeconds' | 'progressUpdatedAt'>
  ) | (
    { __typename: 'WeekendShiftGoalTracker' }
    & Pick<Types.WeekendShiftGoalTracker, 'id' | 'availability'>
    & { trackedVisit?: Types.Maybe<(
      { __typename?: 'WeekendShiftGoalTrackerVisitDetail' }
      & Pick<Types.WeekendShiftGoalTrackerVisitDetail, 'id' | 'rangeStart'>
    )> }
  ) | (
    { __typename: 'BillableHoursGoalTracker' }
    & Pick<Types.BillableHoursGoalTracker, 'id' | 'targetBillableHours' | 'trackedBillableHours' | 'requiresEVVCompliance' | 'availability'>
  ), goalDetails?: Types.Maybe<(
    { __typename?: 'SurveyGoalDetails' }
    & Pick<Types.SurveyGoalDetails, 'externalSurveyUrl' | 'anonymousSubmission'>
  ) | (
    { __typename?: 'WatchVideoGoalDetails' }
    & Pick<Types.WatchVideoGoalDetails, 'videoId'>
  ) | (
    { __typename?: 'CompleteTrainingGoalDetails' }
    & Pick<Types.CompleteTrainingGoalDetails, 'trainingProgramName' | 'trainingProgramDurationSeconds'>
  )>, unlockCriteria?: Types.Maybe<(
    { __typename: 'DependingGoalUnlockCriteria' }
    & { dependingGoal: (
      { __typename?: 'Goal' }
      & Pick<Types.Goal, 'id' | 'type'>
      & { goalTracker: (
        { __typename: 'JobShareGoalTracker' }
        & Pick<Types.JobShareGoalTracker, 'id'>
      ) | (
        { __typename: 'SurveyGoalTracker' }
        & Pick<Types.SurveyGoalTracker, 'id' | 'surveyType'>
      ) | (
        { __typename: 'EVVStreakGoalTracker' }
        & Pick<Types.EvvStreakGoalTracker, 'id' | 'targetStreak'>
        & { trackedVisits: Array<(
          { __typename?: 'EVVStreakGoalTrackerVisitDetail' }
          & Pick<Types.EvvStreakGoalTrackerVisitDetail, 'id' | 'rangeStart' | 'rangeEnd' | 'status'>
        )> }
      ) | (
        { __typename: 'WatchVideoGoalTracker' }
        & Pick<Types.WatchVideoGoalTracker, 'id' | 'videoType'>
      ) | (
        { __typename: 'HHAXLoginGoalTracker' }
        & Pick<Types.HhaxLoginGoalTracker, 'id'>
      ) | (
        { __typename: 'LastMinuteShiftGoalTracker' }
        & Pick<Types.LastMinuteShiftGoalTracker, 'id'>
      ) | { __typename: 'CompleteTrainingGoalTracker' } | (
        { __typename: 'WeekendShiftGoalTracker' }
        & Pick<Types.WeekendShiftGoalTracker, 'id'>
      ) | (
        { __typename: 'BillableHoursGoalTracker' }
        & Pick<Types.BillableHoursGoalTracker, 'id' | 'targetBillableHours' | 'trackedBillableHours' | 'requiresEVVCompliance'>
      ) }
    ) }
  ) | (
    { __typename: 'TimeBasedlUnlockCriteria' }
    & Pick<Types.TimeBasedlUnlockCriteria, 'unlocksAt'>
  )> }
);

export const GoalCardFragmentFragmentDoc = gql`
    fragment GoalCardFragment on Goal {
  id
  type
  status
  numPoints
  numCaribouSponsoredDrawTickets
  numOrganizationDrawTickets
  progress
  goalTracker {
    ... on JobShareGoalTracker {
      id
    }
    ... on SurveyGoalTracker {
      id
      surveyType
    }
    ... on HHAXLoginGoalTracker {
      id
    }
    ... on EVVStreakGoalTracker {
      id
      targetStreak
      currentStreak
      trackedVisits {
        id
        rangeStart
        rangeEnd
        status
      }
    }
    ... on WatchVideoGoalTracker {
      id
      videoType
    }
    ... on HHAXLoginGoalTracker {
      id
    }
    ... on CompleteTrainingGoalTracker {
      id
      programDurationCompletedSeconds
      progressUpdatedAt
    }
    ... on LastMinuteShiftGoalTracker {
      id
      trackedVisit {
        id
        rangeStart
      }
      availability
    }
    ... on WeekendShiftGoalTracker {
      id
      trackedVisit {
        id
        rangeStart
      }
      availability
    }
    ... on BillableHoursGoalTracker {
      id
      targetBillableHours
      trackedBillableHours
      requiresEVVCompliance
      availability
    }
    __typename
  }
  goalDetails {
    ... on SurveyGoalDetails {
      externalSurveyUrl
      anonymousSubmission
    }
    ... on WatchVideoGoalDetails {
      videoId
    }
    ... on CompleteTrainingGoalDetails {
      trainingProgramName
      trainingProgramDurationSeconds
    }
  }
  unlockCriteria {
    ... on DependingGoalUnlockCriteria {
      dependingGoal {
        id
        type
        goalTracker {
          ... on JobShareGoalTracker {
            id
          }
          ... on SurveyGoalTracker {
            id
            surveyType
          }
          ... on HHAXLoginGoalTracker {
            id
          }
          ... on EVVStreakGoalTracker {
            id
            targetStreak
            trackedVisits {
              id
              rangeStart
              rangeEnd
              status
            }
          }
          ... on WatchVideoGoalTracker {
            id
            videoType
          }
          ... on LastMinuteShiftGoalTracker {
            id
          }
          ... on WeekendShiftGoalTracker {
            id
          }
          ... on BillableHoursGoalTracker {
            id
            targetBillableHours
            trackedBillableHours
            requiresEVVCompliance
          }
          __typename
        }
      }
    }
    ... on TimeBasedlUnlockCriteria {
      unlocksAt
    }
    __typename
  }
  trackName
  expiresAt
  activatedAt
  achievedAt
}
    `;