import { differenceInDays, differenceInHours, startOfDay } from "date-fns";
import { ReactNode, useState } from "react";

import {
  DependingGoalUnlockCriteria,
  Goal,
  GoalStatus,
  GoalType,
  Maybe,
  TimeBasedlUnlockCriteria,
  SchedulingSoftware,
  UsStateAbbreviation,
} from "@rewards-web/shared/graphql-types";
import { shouldBeNever } from "@rewards-web/shared/lib/should-be-never";
import { useTrack } from "@rewards-web/shared/modules/analytics";
import { reportError } from "@rewards-web/shared/modules/error";
import { useFormatters } from "@rewards-web/shared/modules/formatter";
import { useSnackbar } from "@rewards-web/shared/modules/snackbar";

import { GoalIcon } from "../goal-icon";
import { useFormattedGoalTitle } from "../use-formatted-goal-title";
import { useActivateGoalMutation } from "./activate-goal.generated";
import { BaseGoalCardProps } from "./base-goal-card";
import { GoalCardFragmentFragment } from "./goal-card-fragment.generated";

export function useCommonBaseGoalCardProps({
  goal,
  cardContext,
  pathToTrack,
  goalActivated,
  lockedDetailModalTextOverride,
}: {
  goal: GoalCardFragmentFragment;
  cardContext: "home" | "subpage";
  pathToTrack?: string;
  goalActivated?: boolean;
  lockedDetailModalTextOverride?(
    unlockCriteria:
      | ({ __typename: "DependingGoalUnlockCriteria" } & Pick<
          DependingGoalUnlockCriteria,
          "__typename"
        >)
      | ({ __typename: "TimeBasedlUnlockCriteria" } & Pick<
          TimeBasedlUnlockCriteria,
          "unlocksAt"
        >)
  ): ReactNode;
}): Pick<
  BaseGoalCardProps,
  | "icon"
  | "analyticsData"
  | "status"
  | "rewardConfig"
  | "topRightPillProps"
  | "detailButtonConfig"
  | "title"
> {
  const topRightPillProps = useTopRightPillProps({ goal });
  const title = useFormattedGoalTitle(goal);
  const { formatMessage } = useFormatters();

  const dependingGoalTitle = useFormattedGoalTitle(
    goal.unlockCriteria?.__typename === "DependingGoalUnlockCriteria"
      ? goal.unlockCriteria.dependingGoal
      : null
  );

  return {
    icon: <GoalIcon goalType={goal.type} />,
    analyticsData: {
      goalId: goal.id,
      goalType: goal.type,
      goalStatus: goal.status,
      goalExpiry: goal.expiresAt,
      goalActivated,
      cardContext,
    },
    status: (() => {
      switch (goal.status) {
        case GoalStatus.Available:
          return "available";
        case GoalStatus.Achieved:
          return "achieved";
        case GoalStatus.Locked:
        default:
          return "locked";
      }
    })(),
    rewardConfig: {
      points: {
        numPoints: goal.numPoints ?? undefined,
        labelType: "default",
      },
      caribouSponsoredDrawTickets: goal.numCaribouSponsoredDrawTickets
        ? {
            numCaribouSponsoredDrawTickets: goal.numCaribouSponsoredDrawTickets,
          }
        : undefined,
      organizationDrawTickets: goal.numOrganizationDrawTickets
        ? {
            numOrganizationDrawTickets: goal.numOrganizationDrawTickets,
          }
        : undefined,
    },
    topRightPillProps,
    title,

    detailButtonConfig: ((): BaseGoalCardProps["detailButtonConfig"] => {
      if (goal.status === GoalStatus.Locked && goal.unlockCriteria) {
        if (lockedDetailModalTextOverride) {
          const override = lockedDetailModalTextOverride(goal.unlockCriteria);

          if (override) {
            return {
              type: "modal",
              contentsText: override,
            };
          }
        }

        // by default,
        // locked goals should show a modal with some default copy
        // about how the goal should be unlocked.

        switch (goal.unlockCriteria?.__typename) {
          case "TimeBasedlUnlockCriteria":
            return {
              type: "modal",
              contentsText: formatMessage(
                {
                  description:
                    "Goal card > locked goal modal text unlocks on date",
                  defaultMessage: "This goal unlocks in {num_days} days.",
                },
                {
                  num_days: getGoalUnlocksInDays(goal.unlockCriteria.unlocksAt),
                }
              ),
            };
          case "DependingGoalUnlockCriteria":
            return {
              type: "modal",
              contentsText: formatMessage(
                {
                  description:
                    "Goal card > locked goal modal text unlocks when depending goal achieved",
                  defaultMessage:
                    "Unlock this goal by completing <bold>{depending_goal_title}</bold>.",
                },
                {
                  depending_goal_title: dependingGoalTitle!,
                  bold: (nodes) => <strong>{nodes}</strong>,
                }
              ),
            };
          case undefined:
            // shouldn't happen - locked goals should have criteria
            return undefined;
          default:
            shouldBeNever(goal.unlockCriteria);
        }

        return undefined;
      }

      if (cardContext === "home" && pathToTrack) {
        return { type: "link", path: pathToTrack };
      }
    })(),
  };
}

function useTopRightPillProps({
  goal,
}: {
  goal: Pick<Goal, "status" | "expiresAt" | "type"> & {
    unlockCriteria?: Maybe<
      | ({ __typename: "DependingGoalUnlockCriteria" } & Pick<
          DependingGoalUnlockCriteria,
          "__typename"
        >)
      | ({ __typename: "TimeBasedlUnlockCriteria" } & Pick<
          TimeBasedlUnlockCriteria,
          "unlocksAt"
        >)
    >;
  };
}): BaseGoalCardProps["topRightPillProps"] {
  const { formatMessage } = useFormatters();
  switch (goal.status) {
    case GoalStatus.Locked:
      return goal.unlockCriteria?.__typename === "TimeBasedlUnlockCriteria"
        ? {
            text: formatMessage(
              {
                defaultMessage:
                  "In {unlocks_in_days} {unlocks_in_days, plural, one {day} other {days}}",
                description: "Goal card > expires in days",
              },
              {
                unlocks_in_days: getGoalUnlocksInDays(
                  goal.unlockCriteria.unlocksAt
                ),
              }
            ),
            variant: "light",
          }
        : undefined;
    case GoalStatus.Available: {
      if (goal.type === GoalType.Survey && goal.expiresAt) {
        const hoursUntilExpiry = differenceInHours(goal.expiresAt, new Date());
        if (hoursUntilExpiry <= 72) {
          return {
            text: formatMessage(
              {
                defaultMessage:
                  "{hours_until_expiry} {hours_until_expiry, plural, one {hour} other {hours}} left",
                description: "Goal card > expires in hours",
              },
              {
                hours_until_expiry: hoursUntilExpiry,
              }
            ),
            variant: "warning",
          };
        }
      }

      return goal.expiresAt
        ? {
            text: formatMessage(
              {
                defaultMessage:
                  "{expires_in_days} {expires_in_days, plural, one {day} other {days}} left",
                description: "Goal card > expires in days",
              },
              {
                expires_in_days: differenceInDays(
                  goal.expiresAt,
                  startOfDay(new Date())
                ),
              }
            ),
            variant: "light",
          }
        : undefined;
    }
    case GoalStatus.Achieved:
      return {
        text: formatMessage({
          defaultMessage: "Complete",
          description: "Goal card > complete",
        }),
        variant: "tertiary",
      };
    default:
      return undefined;
  }
}

export function useGoalActivation() {
  const [activationLoading, setActivationLoading] = useState(false);
  const [activateGoal] = useActivateGoalMutation();
  const track = useTrack();
  const snackbar = useSnackbar();
  const { formatMessage } = useFormatters();

  async function activateGoalAndTrack(goal: GoalCardFragmentFragment) {
    try {
      setActivationLoading(true);
      await activateGoal({
        variables: {
          goalId: goal.id,
        },
        optimisticResponse: {
          activateGoal: {
            ...goal,
            activatedAt: new Date(),
          },
        },
      });

      snackbar.show({
        message: formatMessage({
          description: "Goal activation > success message",
          defaultMessage: "Your interest has been submitted",
        }),
        severity: "success",
      });
      track("Goal activated", { goalId: goal.id, goalType: goal.type });
      return { result: "activated" };
    } catch (error) {
      reportError(error);
      snackbar.show({
        severity: "error",
        message: "An unexpected error occurred. Please try again later.",
      });
      return { result: "failed" };
    } finally {
      setActivationLoading(false);
    }
  }

  return {
    activateGoalAndTrack,
    activationLoading,
  };
}

export function getGoalUnlocksInDays(unlocksAt: Date) {
  return Math.max(differenceInDays(unlocksAt, startOfDay(new Date())), 0);
}

export function secondsToHoursAndMinutes(seconds: number) {
  const hours = Math.floor(seconds / 3600);
  const minutes = Math.floor((seconds % 3600) / 60);
  return { hours, minutes };
}

export type SchedulingSoftwareDetails = {
  appName: string;
  appStoreLink: string;
  playStoreLink: string;
};

export function getSchedulingSoftwareDetails({
  schedulingSoftware,
  stateAbbreviation,
}: {
  schedulingSoftware: SchedulingSoftware | undefined | null;
  stateAbbreviation?: UsStateAbbreviation | null;
}): SchedulingSoftwareDetails | null {
  switch (schedulingSoftware) {
    case SchedulingSoftware.Hhaexchange: {
      const { appStoreLink, playStoreLink } = (() => {
        switch (stateAbbreviation) {
          case UsStateAbbreviation.Tx:
          case UsStateAbbreviation.Il:
          case UsStateAbbreviation.Ma:
          case UsStateAbbreviation.Mi:
            // Specific HHAX app for Texas, Illinois, Massachusetts, and Michigan
            return {
              appStoreLink:
                "https://apps.apple.com/us/app/hhaexchange/id1623129489",
              playStoreLink:
                "https://play.google.com/store/apps/details?id=com.hhaexchange.uma",
            };
          default:
            return {
              appStoreLink:
                "https://apps.apple.com/us/app/hhaexchange/id883673336",
              playStoreLink:
                "https://play.google.com/store/apps/details?id=com.hhaexchange.caregiver",
            };
        }
      })();

      return {
        appName: "HHAeXchange",
        appStoreLink,
        playStoreLink,
      };
    }

    case SchedulingSoftware.Alayacare:
      return {
        appName: "AlayaCare",
        appStoreLink: "https://apps.apple.com/us/app/alayacare/id1030754584",
        playStoreLink:
          "https://play.google.com/store/apps/details?id=com.alayacare.careworkerapp",
      };
    case SchedulingSoftware.Axiscare:
      return {
        appName: "AxisCare",
        appStoreLink:
          "https://apps.apple.com/us/app/axiscare-mobile/id1081635097",
        playStoreLink:
          "https://play.google.com/store/apps/details?id=com.axiscare",
      };
    case SchedulingSoftware.Wellsky:
      return {
        appName: "WellSky",
        appStoreLink:
          "https://apps.apple.com/us/app/wellsky-personal-care/id1304726452",
        playStoreLink:
          "https://play.google.com/store/apps/details?id=com.clearcare.clearcareconnect",
      };
    case SchedulingSoftware.Unknown:
    case undefined:
    case null:
      return null;

    default:
      shouldBeNever(schedulingSoftware); // avoid app crash when new scheduling software is added and the mapping hasn't been updated
      return null;
  }
}
