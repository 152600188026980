import * as Types from '@rewards-web/shared/graphql-types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type BillableHoursTrackPageDataQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type BillableHoursTrackPageDataQuery = (
  { __typename?: 'Query' }
  & { getMyRewardsOrganizationEVVRules: (
    { __typename?: 'RewardsOrganizationEVVRules' }
    & Pick<Types.RewardsOrganizationEvvRules, 'id'>
    & { rules: (
      { __typename?: 'EVVRules' }
      & Pick<Types.EvvRules, 'minutesLessThanScheduledStart' | 'minutesGreaterThanScheduledStart' | 'minutesLessThanScheduledEnd' | 'minutesGreaterThanScheduledEnd' | 'minutesLessThanScheduledDuration' | 'minutesGreaterThanScheduledDuration' | 'validClockMethods'>
    ) }
  ) }
);


export const BillableHoursTrackPageDataDocument = gql`
    query BillableHoursTrackPageData {
  getMyRewardsOrganizationEVVRules {
    id
    rules {
      minutesLessThanScheduledStart
      minutesGreaterThanScheduledStart
      minutesLessThanScheduledEnd
      minutesGreaterThanScheduledEnd
      minutesLessThanScheduledDuration
      minutesGreaterThanScheduledDuration
      validClockMethods
    }
  }
}
    `;

/**
 * __useBillableHoursTrackPageDataQuery__
 *
 * To run a query within a React component, call `useBillableHoursTrackPageDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useBillableHoursTrackPageDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBillableHoursTrackPageDataQuery({
 *   variables: {
 *   },
 * });
 */
export function useBillableHoursTrackPageDataQuery(baseOptions?: Apollo.QueryHookOptions<BillableHoursTrackPageDataQuery, BillableHoursTrackPageDataQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<BillableHoursTrackPageDataQuery, BillableHoursTrackPageDataQueryVariables>(BillableHoursTrackPageDataDocument, options);
      }
export function useBillableHoursTrackPageDataLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<BillableHoursTrackPageDataQuery, BillableHoursTrackPageDataQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<BillableHoursTrackPageDataQuery, BillableHoursTrackPageDataQueryVariables>(BillableHoursTrackPageDataDocument, options);
        }
export type BillableHoursTrackPageDataQueryHookResult = ReturnType<typeof useBillableHoursTrackPageDataQuery>;
export type BillableHoursTrackPageDataLazyQueryHookResult = ReturnType<typeof useBillableHoursTrackPageDataLazyQuery>;
export type BillableHoursTrackPageDataQueryResult = Apollo.QueryResult<BillableHoursTrackPageDataQuery, BillableHoursTrackPageDataQueryVariables>;