/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { faHourglass } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { alpha } from "@material-ui/core";

import { Skeleton } from "@rewards-web/shared/components/skeleton";
import { Typography } from "@rewards-web/shared/components/typography";
import { useAppTheme } from "@rewards-web/shared/style/theme";
import { AppTheme } from "@rewards-web/shared/style/types";

export interface GoalPillProps {
  text: string;
  variant: "light" | "warm" | "tertiary" | "warning";
  loading?: boolean;
  locked: boolean;
}

export function GoalCardPill({
  text,
  variant,
  loading,
  locked,
}: GoalPillProps) {
  const theme = useAppTheme();
  if (loading) {
    return <Skeleton height="22px" width="100px" animated />;
  }
  return (
    <div
      css={(theme: AppTheme) => css`
        display: flex;
        align-items: center;
        padding: 0 ${theme.spacing(0.75)};
        border-radius: 8px;
        background-color: ${(() => {
          switch (variant) {
            case "tertiary":
              return theme.palette.tertiary.main;
            case "warm":
              return locked ? theme.palette.grey[400] : theme.palette.grey[200];
            case "light":
              return locked ? "#636666" : "white";
            case "warning":
              return theme.palette.error.main;
            default:
              return theme.palette.background.default;
          }
        })()};
        user-select: none;
        padding: 1px ${theme.spacing(1.25)};
      `}
    >
      <Typography
        variant="caption"
        fontWeight={500}
        color={(() => {
          switch (variant) {
            case "light":
              return locked ? "white" : "textPrimary";
            case "tertiary":
              return "white";
            case "warm":
              return alpha(theme.palette.text.primary, locked ? 0.5 : 1);
            case "warning":
              return "white";
            default:
              return "textPrimary";
          }
        })()}
      >
        {variant === "warning" && (
          <FontAwesomeIcon
            icon={faHourglass}
            size="sm"
            css={(theme: AppTheme) => css`
              margin-right: ${theme.spacing(0.5)};
            `}
          />
        )}
        {text}
      </Typography>
    </div>
  );
}
