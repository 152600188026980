import {
  BillableHoursGoalTracker,
  CompleteTrainingGoalDetails,
  EvvStreakGoalTracker,
  Goal,
  GoalType,
} from "@rewards-web/shared/graphql-types";
import { useFormatters } from "@rewards-web/shared/modules/formatter";

export type FormattedGoalParam = Pick<Goal, "type"> & {
  goalTracker:
    | { __typename: "JobShareGoalTracker" }
    | { __typename: "SurveyGoalTracker" }
    | ({ __typename: "EVVStreakGoalTracker" } & Pick<
        EvvStreakGoalTracker,
        "id" | "targetStreak"
      >)
    | { __typename: "WatchVideoGoalTracker" }
    | { __typename: "HHAXLoginGoalTracker" }
    | { __typename: "LastMinuteShiftGoalTracker" }
    | { __typename: "WeekendShiftGoalTracker" }
    | { __typename: "CompleteTrainingGoalTracker" }
    | ({ __typename: "BillableHoursGoalTracker" } & Pick<
        BillableHoursGoalTracker,
        "targetBillableHours"
      >);
} & {
  goalDetails?:
    | { __typename?: "SurveyGoalDetails" }
    | { __typename?: "WatchVideoGoalDetails" }
    | ({ __typename?: "CompleteTrainingGoalDetails" } & Pick<
        CompleteTrainingGoalDetails,
        "trainingProgramName"
      >)
    | null;
};

/**
 * Formats a goal's title.

 * If the provided `goal` is `null`, returns `null`.
 */
export function useFormattedGoalTitle(goal: null): null;
export function useFormattedGoalTitle(goal: FormattedGoalParam): string;
export function useFormattedGoalTitle(
  goal: FormattedGoalParam | null
): string | null;
export function useFormattedGoalTitle(
  goal: FormattedGoalParam | null
): string | null {
  const { formatMessage } = useFormatters();

  if (goal === null) {
    return null;
  }

  switch (goal.type) {
    case GoalType.WatchVideo:
      return formatMessage({
        description: "Goal title > watch video",
        defaultMessage: "Watch video",
      });
    case GoalType.Survey:
      return formatMessage({
        description: "Goal title > survey",
        defaultMessage: "Take survey",
      });
    case GoalType.ShareJob:
      return formatMessage({
        description: "Goal title > share job",
        defaultMessage: "Refer a friend",
      });
    case GoalType.EvvStreak: {
      const targetStreak = (goal.goalTracker as EvvStreakGoalTracker)
        .targetStreak;

      return targetStreak === 1
        ? formatMessage({
            description: "Goal title > evv streak of 1",
            defaultMessage: "Clock in to your next visit",
          })
        : formatMessage(
            {
              description: "Goal title > evv streak",
              defaultMessage: "Clock in and out ({num_target_streak}x)",
            },
            {
              num_target_streak: (goal.goalTracker as EvvStreakGoalTracker)
                .targetStreak,
            }
          );
    }
    case GoalType.HhaxLogin:
      return formatMessage({
        description: "Goal title > hhax login",
        defaultMessage: "Get HHAeXchange app",
      });
    case GoalType.LastMinuteShift:
      return formatMessage({
        description: "Goal title > last minute shift",
        defaultMessage: "Last minute shift",
      });
    case GoalType.WeekendShift:
      return formatMessage({
        description: "Goal title > weekend shift",
        defaultMessage: "Weekend shift",
      });
    case GoalType.CompleteTraining: {
      const trainingProgramName = (goal.goalDetails as CompleteTrainingGoalDetails)
        .trainingProgramName;

      // title not formatted since it is User Generated Content
      return trainingProgramName;
    }
    case GoalType.BillableHours:
      return formatMessage(
        {
          description: "Goal title > billable hours",
          defaultMessage: "Work {target_billable_hours} hours",
        },
        {
          target_billable_hours: (goal.goalTracker as BillableHoursGoalTracker)
            .targetBillableHours,
        }
      );
    default:
      // fall back to a generic message (this should ideally not happen)
      return formatMessage({
        description: "Goal title > unknown goal",
        defaultMessage: "Unknown goal",
      });
  }
}
