import { useNavigate } from "react-router-dom";

import {
  BillableHoursGoalTracker,
  GoalStatus,
} from "@rewards-web/shared/graphql-types";
import { useFormatters } from "@rewards-web/shared/modules/formatter";

import { GoalCardProps } from "..";
import briefcaseImageUrl from "../../graphics/briefcase.png";
import { BaseGoalCard } from "../base-goal-card";
import { useCommonBaseGoalCardProps, useGoalActivation } from "../lib";

export function BillableHoursGoalCard({ goal, cardContext }: GoalCardProps) {
  const navigate = useNavigate();
  const { formatMessage, formatDate } = useFormatters();
  const { activateGoalAndTrack, activationLoading } = useGoalActivation();
  const commonBaseGoalCardProps = useCommonBaseGoalCardProps({
    goal,
    cardContext,
    pathToTrack: "/goal-track/billable-hours",
  });

  const buttonLabel = (() => {
    if (
      goal.activatedAt &&
      (goal.goalTracker as BillableHoursGoalTracker).availability
    ) {
      return formatMessage({
        defaultMessage: "Requested",
        description:
          "Goal card > billable hours > button label activated and submitted availability",
      });
    } else if (goal.activatedAt) {
      return formatMessage({
        defaultMessage: "Add availability",
        description:
          "Goal card > billable hours > button label activated and no availability submitted",
      });
    } else {
      return formatMessage({
        defaultMessage: "I'm interested",
        description: "Goal card > billable hours > button label not activated",
      });
    }
  })();

  const handlePrimaryButtonClick = async () => {
    if (goal.activatedAt) {
      navigate(`/submit-goal-availability/${goal.id}`);
    } else {
      const { result } = await activateGoalAndTrack(goal);
      if (result === "activated") {
        navigate(`/submit-goal-availability/${goal.id}`);
      }
    }
  };

  const detailText = (() => {
    switch (goal.status) {
      case GoalStatus.Achieved:
        return formatDate(goal.achievedAt, {
          weekday: "long",
          year: "numeric",
          month: "numeric",
          day: "numeric",
          hour: "numeric",
          minute: "numeric",
          hour12: true,
        });
      case GoalStatus.Available:
      case GoalStatus.Locked: {
        if (
          goal.activatedAt &&
          (goal.goalTracker as BillableHoursGoalTracker).trackedBillableHours >
            0
        ) {
          return formatMessage(
            {
              defaultMessage:
                "Increase your hours this week and earn points when you reach your goal by {goal_expires_at, date, long}. Shift data may take several days to be reflected.",
              description:
                "Goal card > billable hours > activated with tracked hours detail text",
            },
            {
              goal_expires_at: new Date(goal.expiresAt),
            }
          );
        } else if (goal.activatedAt) {
          return formatMessage({
            defaultMessage:
              "A coordinator will contact you to increase your hours accordingly. Shift data may take several days to be reflected.",
            description: "Goal card > billable hours > activated detail text",
          });
        } else {
          return formatMessage(
            {
              defaultMessage:
                "Increase your hours this week and earn points when you reach your goal by {goal_expires_at, date, long}.",
              description: "Goal card > billable hours > detail text",
            },
            {
              goal_expires_at: new Date(goal.expiresAt),
            }
          );
        }
      }
    }
  })();

  return (
    <BaseGoalCard
      {...commonBaseGoalCardProps}
      progressProps={
        goal.activatedAt
          ? {
              max: (goal.goalTracker as BillableHoursGoalTracker)
                .targetBillableHours,
              value: (goal.goalTracker as BillableHoursGoalTracker)
                .trackedBillableHours,
              getUnitsLabel: ({ max, value }) =>
                formatMessage(
                  {
                    defaultMessage: "{value, number}/{max, number} hrs",
                    description: "Goal card > billable hours > progress units",
                  },
                  { max, value }
                ),
            }
          : undefined
      }
      icon={<img src={briefcaseImageUrl} alt="calendar" />}
      detailText={detailText}
      primaryButtonProps={
        goal.status === GoalStatus.Available
          ? {
              label: buttonLabel,
              disabled:
                Boolean(goal.activatedAt) &&
                Boolean(
                  (goal.goalTracker as BillableHoursGoalTracker).availability
                ),
              onClick: handlePrimaryButtonClick,
              loading: activationLoading,
            }
          : undefined
      }
    />
  );
}
