/** @jsxImportSource @emotion/react */
import { useNavigate } from "react-router-dom";

import {
  GoalStatus,
  LastMinuteShiftGoalTracker,
} from "@rewards-web/shared/graphql-types";
import { useFormatters } from "@rewards-web/shared/modules/formatter";

import { GoalCardProps } from "..";
import shiftClockImageUrl from "../../graphics/shift-clock.png";
import { BaseGoalCard } from "../base-goal-card";
import { useCommonBaseGoalCardProps, useGoalActivation } from "../lib";

export function LastMinuteShiftGoalCard({ goal, cardContext }: GoalCardProps) {
  const navigate = useNavigate();
  const { formatMessage, formatDate } = useFormatters();
  const { activateGoalAndTrack, activationLoading } = useGoalActivation();
  const commonBaseGoalCardProps = useCommonBaseGoalCardProps({
    goal,
    cardContext,
    pathToTrack: "/goal-track/last-minute-shift",
  });

  const buttonLabel = (() => {
    if (
      goal.activatedAt &&
      (goal.goalTracker as LastMinuteShiftGoalTracker).availability
    ) {
      return formatMessage({
        defaultMessage: "Requested",
        description:
          "Goal card > last minute shift > button label activated and submitted availability",
      });
    } else if (goal.activatedAt) {
      return formatMessage({
        defaultMessage: "Add availability",
        description:
          "Goal card > last minute shift > button label activated and no availability submitted",
      });
    } else {
      return formatMessage({
        defaultMessage: "I'm interested",
        description:
          "Goal card > last minute shift > button label not activated",
      });
    }
  })();

  const detailText = (() => {
    switch (goal.status) {
      case GoalStatus.Achieved:
        return formatDate(goal.achievedAt, {
          weekday: "long",
          year: "numeric",
          month: "numeric",
          day: "numeric",
          hour: "numeric",
          minute: "numeric",
          hour12: true,
        });
      case GoalStatus.Available:
      case GoalStatus.Locked:
        return goal.activatedAt
          ? formatMessage({
              defaultMessage:
                "You're signed up for last minute shift opportunities and will earn points if you complete one",
              description:
                "Goal card > last minute shift > activated details text",
            })
          : formatMessage({
              defaultMessage:
                "Sign up for last-minute shift opportunities and earn points if you complete one",
              description: "Goal card > last minute shift > details text",
            });
      default:
        return undefined;
    }
  })();

  const handlePrimaryButtonClick = async () => {
    if (goal.activatedAt) {
      navigate(`/submit-goal-availability/${goal.id}`);
    } else {
      const { result } = await activateGoalAndTrack(goal);
      if (result === "activated") {
        navigate(`/submit-goal-availability/${goal.id}`);
      }
    }
  };

  return (
    <BaseGoalCard
      {...commonBaseGoalCardProps}
      icon={<img src={shiftClockImageUrl} alt="clock" />}
      detailText={detailText}
      primaryButtonProps={
        goal.status === GoalStatus.Available
          ? {
              label: buttonLabel,
              disabled:
                Boolean(goal.activatedAt) &&
                Boolean(
                  (goal.goalTracker as LastMinuteShiftGoalTracker).availability
                ),
              onClick: handlePrimaryButtonClick,
              loading: activationLoading,
            }
          : undefined
      }
    />
  );
}
