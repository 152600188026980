/** @jsxImportSource @emotion/react */
import { Skeleton } from "@rewards-web/shared/components/skeleton";
import { Typography } from "@rewards-web/shared/components/typography";
import { SurveyGoalDetails } from "@rewards-web/shared/graphql-types";
import { reportError } from "@rewards-web/shared/modules/error";
import { useFormatters } from "@rewards-web/shared/modules/formatter";

import surveyClipboardLargeUrl from "../../../../shared/goal/graphics/survey-clipboard-large.png";
import { BaseGoalTrackPage, BaseGoalTrackPageProps } from "../base-track-page";
import { useSurveyTrackPageDataQuery } from "./survey-track-page-data.generated";

export interface SurveyGoalTrackProps {
  trackData: BaseGoalTrackPageProps["trackData"];
  analyticsData: BaseGoalTrackPageProps["analyticsData"];
}

export function SurveyGoalTrackPage({
  trackData,
  analyticsData,
}: SurveyGoalTrackProps) {
  const query = useSurveyTrackPageDataQuery({
    onError: reportError,
  });

  const { formatMessage } = useFormatters();

  const content = (shortName: string, goalDetails?: SurveyGoalDetails) => {
    if (goalDetails?.anonymousSubmission) {
      return formatMessage(
        {
          defaultMessage:
            "{agency_name} wants to hear from you! Help them improve your experience at work. This survey is anonymous.",
          description: "Goal track page > anonymous survey track description",
        },
        { agency_name: shortName }
      );
    } else {
      return formatMessage(
        {
          defaultMessage:
            "{agency_name} wants to hear from you! Help them improve your experience at work. This survey is identifiable.",
          description:
            "Goal track page > identifiable survey track description",
        },
        { agency_name: shortName }
      );
    }
  };
  return (
    <BaseGoalTrackPage
      trackData={trackData}
      analyticsData={analyticsData}
      title={formatMessage({
        defaultMessage: "Take survey",
        description: "Goal track page > survey track title",
      })}
      icon={<img src={surveyClipboardLargeUrl} alt="clipboard" />}
      content={
        <Typography variant="body">
          {query.data && !trackData?.loading ? (
            content(
              query.data.getMyRewardsOrganization.shortName,
              // TODO: Currently assuming only one available survey goal at a time.
              trackData.availableGoals[0]?.goalDetails as SurveyGoalDetails
            )
          ) : (
            <Skeleton width="100%" height={100} />
          )}
        </Typography>
      }
    />
  );
}
