/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { groupBy, orderBy } from "lodash";
import { useState } from "react";

import { Alert } from "@rewards-web/shared/components/alert";
import { Divider } from "@rewards-web/shared/components/divider";
import { Typography } from "@rewards-web/shared/components/typography";
import { RedemptionMethod } from "@rewards-web/shared/graphql-types";
import { useDrawerControl } from "@rewards-web/shared/hooks/use-drawer-control";
import { useFormatters } from "@rewards-web/shared/modules/formatter";
import { AppTheme } from "@rewards-web/shared/style/types";

import { EarnMorePointsButton } from "../../../earn-more-points-button";
import { InsufficientPointsDrawer } from "../../../points-progress/insufficient-points-drawer";
import { RedeemPointsDrawer } from "../../../points-progress/redeem-points-drawer";
import { CatalogItem } from "./catalog-item";
import { RedemptionCatalogItemFragmentFragment } from "./redemption-catalog-item-fragment.generated";

export interface CatalogItemListProps {
  pointsAvailableToRedeem: number;
  pointsPerDollar: number;
  email: string | null | undefined;
  phoneNumber: string | null | undefined;
  minimumPointsToRedeem: number;
  catalogItems: RedemptionCatalogItemFragmentFragment[];
}

export function CatalogItemList({
  pointsAvailableToRedeem,
  pointsPerDollar,
  email,
  phoneNumber,
  minimumPointsToRedeem,
  catalogItems,
}: CatalogItemListProps) {
  const [
    selectedItem,
    setSelectedItem,
  ] = useState<RedemptionCatalogItemFragmentFragment | null>(null);
  const [drawerData, drawerActions] = useDrawerControl(selectedItem);

  const selectedItemIsLocked = drawerData.state
    ? getItemIsLocked(
        drawerData.state,
        pointsAvailableToRedeem,
        minimumPointsToRedeem
      )
    : false;

  const { formatMessage } = useFormatters();

  if (catalogItems.length === 0) {
    // this shouldn't really happen, so this is a defensive fallback
    return (
      <Alert
        severity="info"
        message={formatMessage({
          defaultMessage:
            "Sorry, there are no items available to redeem. Please come back later!",
          description: "Catalog item list > no items available",
        })}
      />
    );
  }

  const catalogItemsByPrice = orderBy(
    catalogItems,
    (item) => item.priceInPoints,
    "asc"
  );
  const mostInexpensiveCatalogItem = catalogItemsByPrice[0];

  // If the user doesn't have enough points to redeem the most inexpensive item,
  // OR the user doesn't have enough points to redeem the minimum points to redeem,
  // then all items are locked
  const allItemsLocked =
    pointsAvailableToRedeem <
      (mostInexpensiveCatalogItem?.priceInPoints ?? 0) ||
    pointsAvailableToRedeem < minimumPointsToRedeem;

  const catalogItemsGroupedByPrice = orderBy(
    Object.entries(groupBy(catalogItems, (item) => item.priceInPoints)).map(
      ([price, items]) => ({
        priceInPoints: Number(price),
        items,
      })
    ),
    (group) => group.priceInPoints
  );

  return (
    <>
      <Typography variant="h3">
        {allItemsLocked
          ? formatMessage({
              defaultMessage: "🔒 Discover gifts",
              description: "Redeem gifts page > header locked",
            })
          : formatMessage({
              defaultMessage: "Redeem gifts",
              description: "Redeem gifts page > header",
            })}
      </Typography>
      <Typography
        variant="body"
        css={(appTheme: AppTheme) => css`
          margin-bottom: ${appTheme.spacing(2)};
          color: ${appTheme.palette.grey[800]};
        `}
      >
        {allItemsLocked
          ? formatMessage(
              {
                defaultMessage:
                  "Earn {required_points, number} more points and unlock a catalog of amazing gift options.",
                description: "Redeem gift page > subtitle locked",
              },
              {
                required_points:
                  minimumPointsToRedeem - pointsAvailableToRedeem,
              }
            )
          : formatMessage({
              defaultMessage:
                "Choose a reward that's right for you and turn your points into something special!",
              description: "Redeem gift page > subtitle",
            })}
      </Typography>
      {allItemsLocked && <EarnMorePointsButton />}

      {catalogItemsGroupedByPrice.map((group, index) => (
        <div key={group.priceInPoints}>
          <Typography
            variant="h6"
            color="text.primary"
            css={(theme: AppTheme) => css`
              margin-bottom: ${theme.spacing(2)};
            `}
          >
            {formatMessage(
              {
                defaultMessage: "{points, number} points",
                description: "Catalog item list > points price header",
              },
              {
                points: group.priceInPoints,
              }
            )}
          </Typography>
          <div
            css={(theme: AppTheme) => css`
              display: grid;
              grid-template-columns: repeat(1, 1fr);
              @media (min-width: 315px) {
                grid-template-columns: repeat(2, 1fr);
              }
              @media (min-width: 510px) {
                grid-template-columns: repeat(3, 1fr);
              }
              column-gap: ${theme.spacing(2)};
              row-gap: ${theme.spacing(3)};

              margin-bottom: ${theme.spacing(3)};
            `}
          >
            {group.items.map((item) => {
              const locked = getItemIsLocked(
                item,
                pointsAvailableToRedeem,
                minimumPointsToRedeem
              );

              return (
                <CatalogItem
                  key={item.id}
                  item={item}
                  locked={locked}
                  onClick={() => {
                    setSelectedItem(item);
                  }}
                />
              );
            })}
          </div>

          {index < catalogItemsGroupedByPrice.length - 1 && (
            <Divider
              css={(theme: AppTheme) => css`
                margin-bottom: ${theme.spacing(2)};
              `}
            />
          )}
        </div>
      ))}

      <RedeemPointsDrawer
        open={drawerData.open && !selectedItemIsLocked}
        onClose={() => {
          setSelectedItem(null);
        }}
        onExited={() => drawerActions.onExited()}
        pointsAvailableToRedeem={pointsAvailableToRedeem}
        pointsPerDollar={pointsPerDollar}
        email={email}
        phoneNumber={phoneNumber}
        details={{
          redemptionMethod: RedemptionMethod.Catalog,
          catalogItem: drawerData.state ?? undefined,
        }}
      />
      <InsufficientPointsDrawer
        open={drawerData.open && selectedItemIsLocked}
        onClose={() => setSelectedItem(null)}
        onExited={() => drawerActions.onExited()}
        pointsAvailableToRedeem={pointsAvailableToRedeem}
        minimumPointsToRedeem={Math.max(
          drawerData.state?.priceInPoints ?? 0,
          minimumPointsToRedeem
        )}
      />
    </>
  );
}

function getItemIsLocked(
  item: RedemptionCatalogItemFragmentFragment,
  pointsAvailableToRedeem: number,
  minimumPointsToRedeem: number
) {
  return (
    pointsAvailableToRedeem < item.priceInPoints ||
    pointsAvailableToRedeem < minimumPointsToRedeem
  );
}
