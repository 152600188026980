/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";

import { Skeleton } from "@rewards-web/shared/components/skeleton";
import { Typography } from "@rewards-web/shared/components/typography";
import { useFormatters } from "@rewards-web/shared/modules/formatter";
import { AppTheme } from "@rewards-web/shared/style/types";

import { ProgressBar } from "../../../../../shared/progress-bar";
import { roundTrackEarnablePointsValue } from "./lib";

export interface GoalTrackPageHeaderProps {
  title: string;
  icon: JSX.Element;
  trackProgressData:
    | {
        loading: false;
        pointsEarnedSoFar: number;
        totalEarnablePoints: number;
      }
    | { loading: true };
}

export function GoalTrackPageHeader({
  trackProgressData,
  title,
  icon,
}: GoalTrackPageHeaderProps) {
  const { formatMessage } = useFormatters();

  const showPointsEarnedSection = (() => {
    if (trackProgressData.loading) {
      return true;
    }

    return trackProgressData.totalEarnablePoints > 0;
  })();

  const dollarsEarnedText = trackProgressData.loading ? (
    <Skeleton height="32px" width="200px" animated />
  ) : (
    <Typography variant="caption" fontSize="1rem">
      {formatMessage(
        {
          description: "track page > dollars earned",
          defaultMessage:
            "{points_earned_so_far, number} of {total_earnable_points, number}+ points earned",
        },
        {
          points_earned_so_far: trackProgressData.pointsEarnedSoFar,
          total_earnable_points: roundTrackEarnablePointsValue(
            trackProgressData.totalEarnablePoints
          ),
        }
      )}
    </Typography>
  );

  const progressBar = trackProgressData.loading ? (
    <Skeleton height="10px" width="280px" animated />
  ) : (
    <div
      css={css`
        width: 280px;
      `}
    >
      <ProgressBar
        value={trackProgressData.pointsEarnedSoFar}
        max={trackProgressData.totalEarnablePoints}
        height="6px"
        color="primary"
        easingAlgorithm="ease-out-in"
      />
    </div>
  );

  return (
    <div
      css={css`
        position: relative;
        display: flex;
        flex-direction: column;
        align-items: center;
      `}
    >
      <div
        css={(theme: AppTheme) => css`
          padding-bottom: ${theme.spacing(3.75)};
          > * {
            width: 110px;
            height: 110px;
            object-fit: contain;
          }
        `}
      >
        {icon}
      </div>
      <Typography variant="h3">{title}</Typography>
      {showPointsEarnedSection && (
        <>
          <div
            css={(theme: AppTheme) =>
              css`
                margin-top: ${theme.spacing(0.75)};
                margin-bottom: ${theme.spacing(2)};
              `
            }
          >
            {dollarsEarnedText}
          </div>
          {progressBar}
        </>
      )}
    </div>
  );
}
