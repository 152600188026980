/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";

import { PageLoadingState } from "@rewards-web/shared/components/page-loading-state";
import { ErrorPage } from "@rewards-web/shared/pages/error";
import { AppTheme } from "@rewards-web/shared/style/types";

import { PageContentContainer } from "../../../shared/page-content-container";
import { ScrollToTopOnMount } from "../../../shared/scroll-to-top-on-mount";
import { SubPageHeader } from "../../../shared/sub-page-header";
import { useRedemptionPageData } from "../points-summary/hooks";
import { GiftCardList } from "../points-summary/redeem/catalog-giftcard-redemption-info/gift-card-list";

export function RedeemGiftCardsPage() {
  const pageDataQuery = useRedemptionPageData();

  const content = (() => {
    if (pageDataQuery.error) {
      return <ErrorPage />;
    }

    if (!pageDataQuery.data) {
      return <PageLoadingState />;
    }

    return (
      <GiftCardList
        isLocked={pageDataQuery.data.isLocked}
        pointsAvailableToRedeem={pageDataQuery.data.pointsAvailableToRedeem}
        pointsPerDollar={pageDataQuery.data.pointsPerDollar}
        minimumPointsToRedeem={pageDataQuery.data.minimumPointsToRedeem}
        email={pageDataQuery.data.email}
        phoneNumber={pageDataQuery.data.phoneNumber}
        country={pageDataQuery.data.country}
      />
    );
  })();

  return (
    <div
      css={(theme: AppTheme) => css`
        height: 100%;
        overflow-y: scroll;
        background-color: white;
        padding-bottom: ${theme.spacing(2)};
      `}
    >
      <SubPageHeader
        analyticsPageName="Redeem gift card"
        backTo="rewards"
        color="white"
      />

      <PageContentContainer>
        <ScrollToTopOnMount />
        {content}
      </PageContentContainer>
    </div>
  );
}
