/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { differenceInSeconds } from "date-fns";

import { Typography } from "@rewards-web/shared/components/typography";
import { useFormatters } from "@rewards-web/shared/modules/formatter";
import { AppTheme } from "@rewards-web/shared/style/types";

import { ProgressBar } from "../../../../progress-bar";
import { secondsToHoursAndMinutes } from "../../lib";

export interface GoalCardProgressBarProps {
  value: number;
  max: number;
  easingAlgorithm?: "linear" | "ease-out-in";
  getUnitsLabel?: (params: { value: number; max: number }) => string;
  progressUpdatedAt?: Date;
}

export function GoalCardProgressBar({
  value,
  max,
  getUnitsLabel,
  easingAlgorithm,
  progressUpdatedAt,
}: GoalCardProgressBarProps) {
  const { formatMessage, formatDuration } = useFormatters();

  const lastUpdatedAtText: string | undefined = (() => {
    if (!progressUpdatedAt) {
      return undefined;
    }

    const { hours, minutes } = secondsToHoursAndMinutes(
      differenceInSeconds(new Date(), progressUpdatedAt)
    );

    if (hours === 0 && minutes === 0) {
      return formatMessage({
        defaultMessage: "Last updated just now",
        description: "Goal card > progress bar > last updated text > now",
      });
    }

    const formattedDuration = formatDuration(
      { hours, minutes },
      { style: "short" }
    );

    return formatMessage(
      {
        defaultMessage: "Last updated {duration} ago",
        description: "Goal card > progress bar > last updated text",
      },
      {
        duration: formattedDuration,
      }
    );
  })();

  return (
    <>
      <div
        css={(theme: AppTheme) => css`
          display: flex;
          min-width: 100%;
          margin: ${theme.spacing(1)} 0;
          ${lastUpdatedAtText && "margin-bottom: 0"};
          align-items: center;
          gap: ${theme.spacing(1.5)};
          ${!getUnitsLabel &&
          css`
            flex: 1;
            @media (min-width: 550px) {
              min-width: 250px;
              width: 100%;
            }
          `}
        `}
      >
        <ProgressBar
          max={max}
          value={value}
          height="8px"
          color="primary"
          easingAlgorithm={easingAlgorithm}
        />
        {getUnitsLabel && (
          <Typography
            variant="caption"
            css={(appTheme: AppTheme) => css`
              text-transform: uppercase;
              margin-left: auto;
              margin-right: ${appTheme.spacing(0.25)};
              text-align: end;
              white-space: nowrap;
              margin-bottom: 1px;
            `}
          >
            {getUnitsLabel({
              value,
              max,
            })}
          </Typography>
        )}
      </div>
      {lastUpdatedAtText && (
        <Typography variant="footnote" color="grey.800">
          {lastUpdatedAtText}
        </Typography>
      )}
    </>
  );
}
