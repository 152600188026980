import * as Types from '@rewards-web/shared/graphql-types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type RewardsDataQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type RewardsDataQuery = (
  { __typename?: 'Query' }
  & { getMyRewardsUser: (
    { __typename?: 'RewardsUser' }
    & Pick<Types.RewardsUser, 'id' | 'email' | 'phoneNumber'>
  ), getMyRewardsOrganization: (
    { __typename?: 'RewardsOrganization' }
    & Pick<Types.RewardsOrganization, 'id' | 'minimumPointsNeededToRedeem' | 'pointsPerDollar' | 'allowedRedemptionMethods' | 'country'>
  ) }
);


export const RewardsDataDocument = gql`
    query RewardsData {
  getMyRewardsUser {
    id
    email
    phoneNumber
  }
  getMyRewardsOrganization {
    id
    minimumPointsNeededToRedeem
    pointsPerDollar
    allowedRedemptionMethods
    country
  }
}
    `;

/**
 * __useRewardsDataQuery__
 *
 * To run a query within a React component, call `useRewardsDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useRewardsDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRewardsDataQuery({
 *   variables: {
 *   },
 * });
 */
export function useRewardsDataQuery(baseOptions?: Apollo.QueryHookOptions<RewardsDataQuery, RewardsDataQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<RewardsDataQuery, RewardsDataQueryVariables>(RewardsDataDocument, options);
      }
export function useRewardsDataLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<RewardsDataQuery, RewardsDataQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<RewardsDataQuery, RewardsDataQueryVariables>(RewardsDataDocument, options);
        }
export type RewardsDataQueryHookResult = ReturnType<typeof useRewardsDataQuery>;
export type RewardsDataLazyQueryHookResult = ReturnType<typeof useRewardsDataLazyQuery>;
export type RewardsDataQueryResult = Apollo.QueryResult<RewardsDataQuery, RewardsDataQueryVariables>;