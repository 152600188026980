import { Alert } from "@rewards-web/shared/components/alert";
import { PageLoadingState } from "@rewards-web/shared/components/page-loading-state";
import { RedemptionMethod } from "@rewards-web/shared/graphql-types";
import { reportError } from "@rewards-web/shared/modules/error";
import { useFormatters } from "@rewards-web/shared/modules/formatter";

import { useRedemptionPageData } from "../../hooks";
import { CatalogGiftcardSelector } from "./catalog-giftcard-selector";
import { CatalogItemList } from "./catalog-item-list";
import { useRedemptionCatalogItemOptionsQuery } from "./catalog-item-list/redemption-catalog-item-options.generated";
import { GiftCardList } from "./gift-card-list";

interface CatalogGiftcardRedemptionInfoProps {
  redemptionMethods: RedemptionMethod[];
  minimumPointsToRedeem: number;
  pointsAvailableToRedeem: number;
  pointsPerDollar: number;
  email: string | undefined | null;
  phoneNumber: string | undefined | null;
  isLocked: boolean;
}

export function CatalogGiftcardRedemptionInfo({
  redemptionMethods,
  minimumPointsToRedeem,
  pointsAvailableToRedeem,
  pointsPerDollar,
  email,
  phoneNumber,
  isLocked,
}: CatalogGiftcardRedemptionInfoProps) {
  if (
    !redemptionMethods.some((method) =>
      [RedemptionMethod.Catalog, RedemptionMethod.GenericGiftCardLink].includes(
        method
      )
    )
  ) {
    throw new Error(
      "Cannot render CatalogGiftcardRedemptionInfo without Catalog or GenericGiftCardLink redemption methods"
    );
  }

  const { formatMessage } = useFormatters();

  const showSelector = redemptionMethods.length >= 2;

  const redemptionPageData = useRedemptionPageData();
  const shouldLoadCatalogItems = redemptionMethods.includes(
    RedemptionMethod.Catalog
  );
  const catalogItemsQuery = useRedemptionCatalogItemOptionsQuery({
    onError: reportError,
    skip: !shouldLoadCatalogItems,
  });

  if (redemptionPageData.error || catalogItemsQuery.error) {
    return (
      <Alert
        severity="error"
        message={formatMessage({
          description:
            "Points summary page > redeem page > points balance load error",
          defaultMessage: "Something went wrong. Please try again later.",
        })}
      />
    );
  }

  if (
    !redemptionPageData.data ||
    (shouldLoadCatalogItems && !catalogItemsQuery.data) ||
    typeof pointsAvailableToRedeem === "undefined"
  ) {
    return <PageLoadingState />;
  }

  return (
    <div>
      {/*
        * Show the selector if the org has both gifts and e-gift cards.
        Otherwise, show just the list of gift cards or catalog items
      */}
      {showSelector ? (
        <CatalogGiftcardSelector methods={redemptionMethods} />
      ) : (
        <>
          {redemptionMethods[0] === RedemptionMethod.Catalog && (
            <CatalogItemList
              pointsAvailableToRedeem={pointsAvailableToRedeem}
              pointsPerDollar={pointsPerDollar}
              email={email}
              phoneNumber={phoneNumber}
              minimumPointsToRedeem={minimumPointsToRedeem}
              catalogItems={
                catalogItemsQuery.data?.getMyAvailableRedemptionCatalogItems ??
                []
              }
            />
          )}
          {redemptionMethods[0] === RedemptionMethod.GenericGiftCardLink && (
            <GiftCardList
              isLocked={isLocked}
              pointsAvailableToRedeem={pointsAvailableToRedeem}
              pointsPerDollar={pointsPerDollar}
              minimumPointsToRedeem={minimumPointsToRedeem}
              email={email}
              phoneNumber={phoneNumber}
              country={redemptionPageData.data.country}
            />
          )}
        </>
      )}
    </div>
  );
}
